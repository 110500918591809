
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
}

.disabled-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

/** {*/
/*  font-family: 'Montserrat', sans-serif !important;*/
/*}*/

.parsing_data {
  font-family: 'Roboto Mono', monospace !important;
}

.react-player {
  width:  100% !important;
  height: auto !important;
}

@media screen and (min-width: 800px) {
  .react-player {
    width:  815px !important;
    height: 416px !important;
  }
}
.react-player > div > iframe {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.mobile-tariffs-block::-webkit-scrollbar {
  display: none;
}

.mobile-tariffs-block {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

iframe{
  overflow: hidden;
}

.parsingButton {
  position: relative;
}

.parsingButton::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 100px;
  padding: 2px;
  background:linear-gradient(45deg,#B531A4, #E0BC3B);
  -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  transition-duration: 500ms;
}

.parsingButton:hover::before {
  background:linear-gradient(45deg,#E0BC3B, #B531A4);
}
